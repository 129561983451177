<template>
  <div class="container bg">
    <div class="pt20">
      <el-row style="">
        <el-col :span="16" :offset="4">
          <div class="box">
            <div class="top">
              <div class="logo">
                <img :src="this.info.face" alt="" />
                <div class="title">
                  <div class="df name">
                    <span>用户名 {{ this.info.name }} </span> <span @click="$router.push({ name: 'edit' })"><i class="el-icon-edit"></i></span>
                  </div>
                  <div class="status"><i class="el-icon-s-custom"></i> 高级用户</div>
                </div>
              </div>
            </div>
            <div class="info">
              <div class="timeBox"><i class="icon1 el-icon-time"></i> 软件到期时间：{{ info.use_time }}</div>
              <div class="timeBox"><i class="icon1 el-icon-data-analysis"></i> 视频合成时长: {{ info.come_time }} 秒</div>
              <!--<div class="timeBox"><i class="icon1 el-icon-brush"></i> AI绘画次数: {{ info.draw_number }} 次</div>-->
              <div v-if="info.calculations == 3 || info.calculations == 1" class="timeBox"><i class="icon1 el-icon-magic-stick"></i> 形象克隆次数: {{ info.image_copy }} 次</div>
              <div v-if="info.calculations == 3 || info.calculations == 2" class="timeBox">
                <i class="icon1 el-icon-connection"></i> 高阶形象克隆次数: {{ info.high_image_copy }} 次
              </div>
              <div class="timeBox"><i class="icon1 el-icon-mic"></i> 声音克隆次数: {{ info.sound_copy }} 次</div>
            </div>
            <div class="tips left">注：时长不足请联系客服增加</div>
            <div class="left mt15">
              <el-button type="primary" @click="logout">退出登录</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "setting",
  data() {
    return {
      name: "测试用户123",
      craft: "3680",
      time: "2023/9/30",
      url: "https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png",
      info: {},
    };
  },
  mounted() {
    // 渲染视频
    this.info = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.info);
  },
  methods: {
    onPaste(event) {
      // 处理粘贴事件
      console.log("onPaste", event.clipboardData.getData("text/plain"));
    },
    logout() {
      console.log(this.$store.state.mInterval);
      clearInterval(this.$store.state.mInterval);
      localStorage.clear();
      this.$router.push({ name: "login" });
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  background: #fff;
  margin: 20px 0;
  margin-bottom: 0;
  height: 80vh;
  border-radius: 9px;
  overflow: hidden;

  .top {
    width: 100%;
    height: 230px;
    background: url(../../assets/img/myBg.png) no-repeat;
    background-size: 100% 100%;
    padding: 60px 30px;

    .logo {
      background: #fff;
      border-radius: 100px;
      width: 106px;
      height: 106px;
      display: flex;
      align-items: center;

      img {
        width: 105px;
        height: 105px;
        border-radius: 50px;
      }
      .title {
        font-weight: bold;
        font-size: 18px;
        margin-left: 25px;

        .name {
          width: 230px;
          text-indent: -12px;
          justify-content: space-around;
          align-items: baseline;
        }
        .status {
          font-weight: 500;
          margin-top: 10px;
          padding: 5px 14px;
          border: 1px solid #ff4800;
          border-radius: 6px;
          width: 130px;
          background: #ff4800;
          color: #fff;
        }
      }
    }
  }

  .info {
    text-align: left;
    margin: 40px 20px 25px;
    font-size: 15px;
    background: #f5f9ff;
    border-radius: 10px;
    padding: 30px 20px;

    .timeBox {
      height: 50px;
      line-height: 50px;

      .icon1 {
        font-size: 18px;
        color: #006dfe;
        margin-right: 12px;
      }
    }
  }
  .tips {
    color: #808080;
    font-size: 14px;
  }

  .left {
    text-align: left;
    margin-left: 30px;
  }
}

::v-deep {
  p {
    max-width: 100%;
    text-align: left;
    text-indent: 24px;
    font-size: 16px;
  }

  .text {
    padding: 30px;
    padding-top: 0px;
  }
}
</style>
